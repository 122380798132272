<template>
    <div>
        <vue-header title="历史报告" isReturn="true" :fixed="true" :isClose="true" :noClose="!Return" :notoken="true"/>
        <div class="cribe-title">
            <div class="cribe-repuer">
                <div class="cribe-margin flex">
                    <div class="prop" @click="()=>weekFun(-1)">
                        <i class="iconfont">&#xe62d;</i>
                    </div>
                    <div class="cribe-time flex-1">
                        <div v-if="time" @click="boolFun">{{time}}</div>
                    </div>
                    <div class="next" @click="()=>weekFun(1)">
                        <i class="iconfont">&#xe62d;</i>
                    </div>
                </div>
            </div>
        </div>
        <div class="prescribe">
            <div class="month flex">
                <div class="button flex-1" @click="()=>monthFun(-1)">上个月</div>
                <div class="button flex-1" @click="()=>censusFun(2)" v-if="census==1">切换成饼图</div>
                <div class="button flex-1" @click="()=>censusFun(1)" v-else>切换折线图</div>
                <div class="button flex-1" @click="()=>monthFun(1)">下个月</div>
            </div>
            <div class="balance-meter" id="census-broken"></div>
            <div class="census-cuer flex">
                <div class="flex-1 flex flex-x flex-y">
                    <div>
                        <div class="cuer">{{judge.max}}</div>
                        <div>最高</div>
                    </div>
                </div>
                <div class="flex-1 flex flex-x flex-y">
                    <div>
                        <div class="cuer">{{judge.average}}</div>
                        <div>平均</div>
                    </div>
                </div>
                <div class="flex-1 flex flex-x flex-y">
                    <div>
                        <div class="cuer">{{judge.min}}</div>
                        <div>最低</div>
                    </div>
                </div>
            </div>
            <div class="prescribe-tion" v-if="type==2 && !report_load && report_list.length>0">
                <router-link :to="`/report/${items.token}`" class="prescribe-box flex flex-y" v-for="(items,indexs) in report_list"  v-bind:key="indexs">
                    <div class="flex-1">
                        <div class="flex">
                            <div class="prescribe-name prescribe-start flex flex-1 flex-y">
                                <div>健康状态：</div>
                                <div class="flex flex-1">
                                    <div><span class="no-start">{{items.score}}</span><span class="divide">分</span></div>
                                    <div class="sick-text">{{items.sick_text}}</div>
                                </div>
                            </div>
                            <div class="flex flex-y">
                                <div class="flex-1"></div>
                                <div>
                                    <div class="prescribe-look">点击查看</div>
                                </div>
                            </div>
                        </div>
                        <div class="prescribe-look">{{items.created_at}}</div>
                    </div>
                    <van-icon name="arrow" />
                </router-link>
            </div>
            <div v-if="!report_load && report_list.length==0" class="no-report">您还没有养生报告！</div>
            <div v-if="report_list.length<total" class="flex flex-x flex-y user-loading"><van-loading /></div>
        </div>
        <div class="shadow" v-if="bool" @click.self="boolFun">
            <div class="white flex flex-x flex-y">
                <div>
                    <div class="white-time flex">
                        <div class="time" @click="()=>startFun(true)">
                            <div class="ash" v-if="start">{{start}}</div>
                            <div class="ash" v-else>开始时间</div>
                        </div>
                        <div class="to">至</div>
                        <div class="time" @click="()=>endFun(true)">
                            <div class="ash" v-if="end">{{end}}</div>
                            <div class="ash" v-else>结束时间</div>
                        </div>
                    </div>
                    <div class="flex flex-x flex-y">
                        <div class="flex">
                            <div class="define" @click="defineFun">确定</div>
                            <div class="reset" @click="reactFun">重置</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="picker-bottom" v-if="startBool">
            <van-datetime-picker
                v-model="currentDate"
                type="date"
                :formatter="formatter"
                title="开始时间"
                :min-date="minDate"
                :max-date="maxDate"
                item-height='35'
                @cancel="startChange"
                @confirm="startInput"
            />
        </div>
        <div class="picker-bottom" v-if="endBool">
            <van-datetime-picker
                v-model="currentDate"
                type="date"
                :formatter="formatter"
                title="结束时间"
                :min-date="minDate"
                :max-date="maxDate"
                item-height='35'
                @cancel="endChange"
                @confirm="endInput"
            />
        </div>
    </div>
</template>

<script>
import { mapState  , mapActions , mapMutations } from 'vuex';
import vueHeader from '@/components/header';
import { dateZero } from "@/utils";
let timer;
export default {
  name: 'reportList',
  components:{
    vueHeader
  },
  data(){
      return {
        minDate: new Date(2020, 0, 1),
        maxDate: new Date(),
        currentDate: new Date(),
        startBool:false,
        endBool:false,
        bool:false,
        start:'',
        end:'',
        Return:this.$route.query.return?this.$route.query.return:false
      }
  },
  created:function(){
      let phone = this.$route.query.phone;
      let sys_company_id = this.$route.query.sys_company_id;
      if(phone && sys_company_id){
            this.axiosTel({
                data:{
                    company_id:sys_company_id,
                    phone:phone,
                    name:'',
                    birthday:''
                },
                _this:this,
                func:this.mainFun
            })
      }else{
          this.mainFun();
      }
  },
  destroyed: function () {
      this.closeFun();
      window.removeEventListener('scroll', this.onSrollFun, false);
  },
  computed:{
      ...mapState('list',['report_list','type','health_load','report_load','total','census','judge','time']),
  },
  methods:{
      mainFun(){
            let token = this.$route.query.user_id;
            if(token){
                sessionStorage.setItem('token',token)
            }
            this.change({key:'type',value:2,user_id:this.$route.params.id});
            window.addEventListener('scroll', this.onSrollFun);
            this.axiosCensus({num:7,day:7});
      },
      back(){
          window.history.back(-1);
      },
      boolFun(){
          this.startBool=false;
          this.endBool=false;
          this.bool=!this.bool;
      },
      endFun(bool){
          this.startBool=false
          this.endBool=bool;
      },
      startFun(bool){
          this.endBool=false;
          this.startBool=bool;
      },
      startChange(){
          this.startBool = !this.startBool;
      },
      endChange(){
          this.endBool = !this.endBool;
      },
      startInput(value){
          let date = new Date(value);
          this.start = `${date.getFullYear()}-${dateZero(date.getMonth()+1)}-${dateZero(date.getDate())}`;
          this.startBool=false;
      },
      endInput(value){
          let date = new Date(value);
          this.end = `${date.getFullYear()}-${dateZero(date.getMonth()+1)}-${dateZero(date.getDate())}`;
          this.endBool=false;
      },
      reactFun(){
          this.start = '';
          this.end = '';
      },
      defineFun(){
          if(this.start && this.end){
              this.boolFun();
              this.dateChange({
                  start:this.start,
                  end:this.end
              })
              this.axiosCensus({num:0,day:0});
          }
      },
      formatter(type, val) {
        if (type === 'year') {
            return val + '年';
        }
        if (type === 'month') {
            return val + '月';
        }
        if (type === 'day') {
            return val + '日';
        }
        return val;
      },
      //折线和饼图
      censusFun(census){
        this.change({
              key:'census',
              value:census
        });
        this.axiosCensus({num:0,day:0});
      },
      onSrollFun(){
            let _this = this;
            clearTimeout(timer);
            timer = setTimeout(()=>{
                let height = document.documentElement.scrollHeight-document.documentElement.scrollTop-document.documentElement.clientHeight;
                //获取滚动条到底部的距离
                if(height<1000){
                    _this.axiosMain({
                        user_id:_this.$route.params.id,
                        type:4
                    });
                }
            },1000)
      },
      ...mapMutations('list',['change','closeFun','monthFun','weekFun','dateChange']),
      ...mapActions('list',['axiosMain','axiosCensus']),
      ...mapActions('device',['axiosTel'])
  },
}
</script>

<style lang="scss" scoped rel="stylesheet/scss">
  @import 'reportList';
</style>
